import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { formatPrice } from 'schema/price.schema';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './cart-total.style.scss';

export const CartTotal = () => {
    const { t } = useTranslation();
    const { cartData } = useCart();
    const { mainUserHasInsurance } = usePrescriptionFlow();
    return (
        <Container fluid className="cart-total d-flex flex-column mt-4 mb-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total--title d-flex justify-content-between">
                        <div>{t('pages.cart.subTotal')}:</div>
                        <div>{formatPrice(cartData?.subTotal)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--title d-flex justify-content-between mt-3">
                        <div>{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(cartData?.shipMethodPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between mt-3 pt-4">
                        <div>{t('pages.cart.estTotal')}</div>
                        <div>{formatPrice(cartData?.total)}</div>
                    </div>
                </Col>
            </Row>
            {cartData?.itemHasUnknownPrice && mainUserHasInsurance && (
                <Row className="mt-4">
                    <ToastBox variant="info">
                        <div className="cart-total--footer-info">{t('pages.cart.totalDisclaimer')}</div>
                    </ToastBox>
                </Row>
            )}
        </Container>
    );
};
